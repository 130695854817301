import { Component, OnInit } from '@angular/core';
import {GetImagesDataService} from './get-imagesdata.service';
import { RetrieveService } from './retrieve.service';





@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],

})
export class AppComponent implements OnInit{

  SiteItems:{}=[]; // for retrieving items as

  constructor(private getImagesDataService:GetImagesDataService,
    private retrieveService:RetrieveService){}


 title = 'app';

 ngOnInit() {
  this.getImagesDataService.initDB();//to Atlas MongoDB
  this.onFetchData();

  }

  async onFetchData(){
        let firstResult = await this.getImagesDataService.getMongoData().then(SiteItems => this.SiteItems = SiteItems);
        let secondResult = await console.log("SiteItems from MongoDB on appComponent: ", this.SiteItems);
        let thirdresult = await this.sEndData()
  }
  async sEndData() {
    // send message to subscribers via observable subject
    let firstResult = this.retrieveService.sendData(this.SiteItems);
    let secondResult = console.log("sEndData ACTIVATED: ",this.SiteItems);
    this.retrieveService.dataStoreLoaded = true;
}



}




