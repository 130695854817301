import { Pipe, PipeTransform } from '@angular/core';
import{Object} from './Object'
@Pipe({
  name: 'filterArgs',
  pure: false
})
export class FilterArgsPipe implements PipeTransform {




  transform(siteItems: any[], filter: Object): any {
    if (!siteItems || !filter) {
        return siteItems;
  }
        // filter items array, items which match and return true will be
        // kept, false will be filtered out
       return siteItems.filter(mongoItem => mongoItem.group.indexOf(filter.group) !== -1);
}
}
