
      <nav class="navbar navbar-light bg-light-gray ">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
      </nav>

      <div class="collapse" id="navbarToggleExternalContent">
        <div class="bg-dark-gray ">
          <div class="d-flex">
            <ul ngbNav #nav="ngbNav"       class="nav-tabs flex-col nav-collapse" data-target= "navbarToggleExternalContent" navbar-light bg-light orientation="vertical">
              <div *ngFor = "let Group of groupArray; index as i" >

                <li   ngbNavItem={{Group.group}} (click)="[(groupPut(i))]" >
                  <a id = "navElements" style ="color: grey;" role="button" data-toggle="collapse" data-target="#navbarToggleExternalContent"  ngbNavLink >{{Group.group}}</a>
                </li>
              </div>
            </ul>
          </div>
      </div>
      </div>
<div class = "container-fluid ">






    <div class="accordion" id="accordionExample">
      <div class="card">
        <div class="card-header" id="headingOne">
          <h2 class="mb-0">
            <a role="button" id = "groupButton" class="btn btn-link bg-transparent "  data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
             <p id = "title ">{{groupString}}</p>

            </a>

          </h2>
        </div>

        <div id="collapseOne" class=" row collapse " aria-labelledby="headingOne" data-parent="#accordionExample">
          <div class="card-body bg-transparent">
            <p id = "text">{{groupText}}</p>
          </div>
        </div>
      </div>




</div>




  <div class = "container">

    <div class="row" *ngIf = "[this.groupString]">
      <div id="loop" *ngFor =  "let mongoItem of siteItems | filterArgs:filter ; index as i" [attr.data-index]="i" class="item col-lg-4 col-md-6 col-sm-6 col-xs-12" >

          <div   id="imgContainer" (click)="open(content,[(mongoItem.file)],[(mongoItem.title)],[(mongoItem.technique)],[(mongoItem.dimensions)],[(mongoItem.date)])">

            <img  id="zoom_05" class = "printmongoItem mx-auto d-block"  src="assets/imgs/artImages/{{mongoItem.file}}"  alt={{mongoItem.title}} >


          </div >

          <div id = "info-block">
            <h4>{{mongoItem.title}}</h4>
            <h5>{{mongoItem.technique}}, {{mongoItem.dimensions}}, {{mongoItem.date}},</h5>
            <h5>{{mongoItem.support}}.</h5>



          </div>

      </div>
    </div>
</div>



<ng-template #content let-modal id="imageModal">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title modal-fluid">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">


      <img  id="modalImage" class = " mx-auto d-block"  src="assets/imgs/artImages/{{retrieveFile}}"  alt={{title}} >

  </div>
  <div class = "container">
    <div class = "row">
      <div class="modal-footer" (click)="modal.close('Save click')">
        <h4>{{technique}}, {{dimensions}}, {{date}}.</h4>
      </div>
      <div>

      </div>

    </div>
  </div>

</ng-template>

