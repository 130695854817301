




<div class="d-flex">
  <ul ngbNav #nav="ngbNav" [(activeId)]="active"      class="nav-pills" navbar-light bg-light orientation="vertical">
    <div *ngFor = "let Group of groupArray; index as i" >

      <li ngbNavItem={{Group.group}} (click)="outPut(i)">
        <a ngbNavLink >{{Group.group}}</a>
      </li>
    </div>
  </ul>
  <ng-template ngbNavContent>
    <p>{{this.currentText}}</p>
  </ng-template>
</div>

<div><p>{{this.currentText}}</p></div>

<div [ngbNavOutlet]="nav" class="ml-4">

</div>
