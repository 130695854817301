import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable()
export class GetSketchesService {

  constructor(private http: HttpClient) { this.getSketchData().subscribe(data => {
console.log(data);
        });
      }
  public getSketchData(): Observable<any>{
    return this.http.get('./assets/sketches.json')
  }

}
