



<iframe src="https://www.artworkarchive.com/profile/wayne-churcher/embed" frameborder="0"></iframe>
<div id="aa_embed" style="clear: both; min-height: 500px"></div>
<script type="text/javascript">
  (function() {
    var d = document, s = d.createElement('script');
    s.type = "text/javascript"; s.async = true;
    s.src = "https://www.artworkarchive.com/profile/wayne-churcher/embed_js.js";
    (d.head || d.body).appendChild(s);
  })();
</script>
