import { Component, OnInit, OnDestroy,HostListener } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';


@Component({
  selector: 'app-sketches',
  templateUrl: './sketches.component.html',
  styleUrls: ['./sketches.component.css']
})

export class SketchesComponent implements OnInit, OnDestroy {


loadAPI: Promise<any>;
//sketchLink:string;
public javaString;
public userId;
public sketchChosen:boolean;

public loadScript() {
 // console.log("this loadScript is found");

//+++ SETTING UP CALLED SCRIPT

//if prev script exists, get rid of it so that only one exists.

let parent = document.getElementsByTagName('head');
    var script = document.getElementById("sketchScript");

if (script !== null){ script.remove();
//console.log("---------- Script refreshed ----------");
}
//create new script for called sketch instance.
let node = document.createElement('script');
            node.id = 'sketchScript';
            node.src = this.javaString;
            node.type = 'text/javascript';
            node.async = false;
            node.charset = 'utf-8';
document.getElementsByTagName('head')[0].appendChild(node);
//console.log("javaString within loadScript = " + this.javaString);



//find params for link
        }

  public IDparams(){
      //console.log("userId = " + this.userId);
      this.javaString = "assets/p5js-sketches/"+ this.userId +".js";
      //console.log("scriptJSLocation =" + this.javaString);
    }

constructor(private route: ActivatedRoute) {}

@HostListener('window:beforeunload')// to check and listen for ngOnDestroy before page instance goes.
  ngOnDestroy(): void {
    var script = document.getElementById("sketchScript");
    script.remove();
  }

  ngOnInit() {

    this.sketchChosen = false;  //sketch-holder in the DOM.
    //for creating  sketch script
//this.sketchChosen = false; //reset of absence of sketchHolder in the template DOM.

    // subscribe to router event
      this.route.params.subscribe((params: Params) => {
          this.userId = params['id'];//userId is passed the sketchName through the routerLink from parent cyberpage
            console.log("UserId = ", 'id');
        });

    this.IDparams(); //made into function to find link params for  sketchelement id and script element

    this.loadAPI = new Promise((resolve) => {
        this.loadScript();
        resolve(true);
    });
  }
}
// params access info from https://alligator.io/angular/query-parameters/

