import { Component, OnInit } from '@angular/core';
import {GroupService} from '../group.service';
import {Group} from '../Group';
@Component({
  selector: 'app-non-print',
  templateUrl: './non-print.component.html',
  styleUrls: ['./non-print.component.css']
})
export class NonPrintComponent implements OnInit {
active = "top";
public groupArray:Group[]=[];
currentText:string;


  constructor(private groupService:GroupService) { }

  ngOnInit() {
    this.GroupData();
  }

  GroupData(){
    //for fetching group info for nav + image selection
    this.groupService.getGroupData().subscribe(data => {
    this.groupArray = data.collection;
    //console.log("groupData = ", this.groupArray)
    //console.log("the first group is: ", this.groupArray[1].group);
    });
    }

    outPut(i){
      this.currentText =  this.groupArray[i].text;
      //console.log("currentText: ", this.currentText )
    }
}
