<div class = "feed-container container-fluid">
<p>
Born in Manukau, New Zealand, March, 1965.
</p>
<p>
Emigrated to Australia in 1989.
</p>
<p>
Recently moved to Wellington, NZ 2015.
</p>
​
<h3>
Solo Exhibitions
</h3>

<p>
  2017, codaMorphology, Thistle Hall Gallery Cuba St., Wellington New Zealand.
</p>
<p>
  2012  ‘Anima’, 3D Virtual Exhibition Space. 3DVAS and waynechurcher.com
</p>
<p>
  2011, Gallery Burlesque, St James Hotel, Sydney, NSW
</p>
<p>
  2011,  'The Solmakon Project’, online project at solmakon.com
</p>
<p>
  2005, Homage, The Global Art Gallery, Darlinghurst, NSW
</p>

<p>
  2000 Alabaster, Pastel Works, 555 Crown St Gallery, Surry Hills, NSW
</p>

<p>
  2000 Alabaster, Pastel Works, 555 Crown St Gallery, Surry Hills, NSW
</p>

<p>
  2000, Recent Works, 555 Crown St Gallery, Surry Hills, NSW
</p>
<p>
  1999, Torso, Regent St Gallery, Redfern, NSW

</p>

<h3>
  Group Exhibitions
</h3>

<p>
  2017, MAC National Printmaking Exhibition, First Impressions III
</p>
​<p>
  2016 100 Days, About Space, 120 High St, Lower Hutt, Wellington, NZ
</p>
<p>
  2014, October, Painters Gallery Art Auction, Many, Sydney, Australia
</p>
<p>
  2014 Market Lane Street Art Project, Manly, Sydney
</p>
<p>
  2014  M13 Artspace Drawing Prize, Canberra, ACT
</p>
<p>
  2013  M13 Artspace Drawing Prize, Canberra, ACT
</p>
<p>
  2012, Drawing 12A, Brunswick Street Gallery, Melbourne, Victoria
</p>
<p>
  2011, Nowa Nowa Nudes, Art Home, Nowa Nowa, Victoria
</p>
<p>
  2006  Northern Expressions Group Exhibition, Dale Thomas Gallery, Artarmon
</p>
<p>
  2004, Good Beginnings Exhibition,  Guangzhou Province, China
</p>

<p>
  2004, Warringah Printmakers Group Exhibition, Manly Vale, NSW
</p>

<p>
  2002 Sacred Moments Art Festival, Kurrajong, NSW
</p>

<p>
  2000, Warringah Printmakers Group Exhibition, Manly Vale, NSW,
</p>

<p>
  1982, Student Exhibition, The Quay, Auckland, New Zealand

</p>

<h3>Installations</h3>

<p>


2014, October, 'Gamer', Charcoal, Aerosol Paint on Board, 2.5x2.5m, Market lane Art Project, Manly.

2014,  June, 'Memory Box', Digital Generative Art Projected Installation, Painters Gallery, Manly.

2014,  June, 'Together', Digital Generative Art Projected Installation, Painters Gallery, Manly.

2014,  June, 'Awareness', Digital Generative Art Projected Installation, Painters Gallery, Manly.

2014, July, 'Totem', Digital Generative Art Projected Installation, Painters Gallery, Manly.

Film

Market lane Art Project, Manly.

Awards
Finalist MAC National Printmaking Exhibition, First Impressions III 2017
Finalist in M13 Artspace Drawing Prize, Canberra, ACT, Australia 2014
Finalist in M13 Artspace Drawing Prize, Canberra, ACT, Australia 2013

2002 Sacred Moments Art Festival, 'Highly Commended Award' for Reclining Figure, Aluminium Plate etching.

Scholarships
​
Oriel Hoskin Scholarship, 2016

Commissions

2013, Private Commission, Tryptych, Manly Beach, 120x60cm giclee print 1/1 .
2012, Rave poster graphics
2014, Promotional event artwork.


Art Studies

(current) Advanced Diploma of Art and Creativity, The Learning Connexion Wellington

2016 Diploma of Visual Art and Creativity(honours) The Learning Connexion Wellington

2010 Commenced BA(Visual Arts) Curtin University, Perth

2000, 2004 Printmaking Courses and works, Warringah

1986, 1987 Life studies, Outreach, Ponsonby Rd, Auckland

1986 Graphic Art Studies, Auckland TAFE

1985 Personal tutoring in airbrush techniques

1981 High School Studies, Visual Art Prize


Past Tertiary Studies

1983-1986 Bachelor of Science (Cell Biology), University of Auckland

1992 Masters Preliminary Thesis (Molecular Biology), University of Sydney
  </p>
</div>
