<nav *ngIf="nav.visible" id = "custom-bootstrap-menu" class="navbar navbar-expand-md navbar-dark bg-dark mb-4 nav-fill " style="background-color: darkgrey;padding-bottom: 10px;">
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <a class="navbar-brand" href="#" ><img src="assets/imgs/favicon3.png" alt="logo" class="img-rounded ">   Waynarium</a>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto flex-col nav-collapse" data-toggle="collapse"  data-target=".navbar-collapse">
        <li class="nav-item text-left ">
            <a class="nav-link" routerLink="/home-page" routerLinkActive="active">Home</a>
              </li>

      <li class="nav-item text-left">
          <a class="nav-link"  routerLink="/now" routerLinkActive="active"  >Now</a>
            </li>

<!--
        <li class="nav-item text-left ">
        <a class="nav-link" routerLink="/print" routerLinkActive="active">Gallery</a>
        </li>

-->
      <li class="nav-item text-left ">
            <a class="nav-link"  routerLink="/bio" routerLinkActive="active" >Bio</a>
            </li>
      <li class="nav-item text-left ">
            <a class="nav-link"  routerLink="/real-world" routerLinkActive="active" >Archive</a>
            </li>
    </ul>

    </div>
</nav>
