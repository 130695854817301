import { Component, AfterViewInit,OnInit,ElementRef, Renderer2, ViewChild, OnDestroy, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-front-sketch',
  templateUrl: './front-sketch.component.html',
  styleUrls: ['./front-sketch.component.css']
})
export class FrontSketchComponent implements OnInit,AfterViewInit, OnDestroy{

  @ViewChild('abcd')
  private abcd: ElementRef;	//abcd is to refer to a parent to attach div  sketchHolderto
  loadAPI: Promise<any>;
  javaString: string= "assets/p5js-sketches/landingSketch1.js";
  public node: Node;



  constructor(private route: ActivatedRoute,private renderer: Renderer2,private elRef: ElementRef) { }




  @HostListener('window:beforeunload')// to check and listen for ngOnDestroy before page instance goes.
  ngOnDestroy(): void {
  this.destroySketch();
    }

  ngOnInit(): void {



  }

  ngAfterViewInit(){
    this.makeDivElement();

  }

  destroySketch(){
    //this.renderer.removeChild(this.elRef.nativeElement, div);

    //const script = this.renderer.selectRootElement("sketchScript");
    console.log("node = ",this.node);



    }

  public createDiv(){
      let varDiv  = document.createElement('div');
      varDiv.id = "SketchHolder";
      document.head.appendChild(varDiv);
    }

  public loadScript() {
      let parent = document.getElementsByTagName('head');
      var script = document.getElementById("sketchScript");

  if (script !== null){ script.remove();
  //console.log("---------- Script refreshed ----------");
  }
  //create new script for called sketch instance.
  let node = document.createElement('script');
              node.id = 'sketchScript';
              node.src = this.javaString;
              node.type = 'text/javascript';
              node.async = false;
              node.charset = 'utf-8';
  document.getElementsByTagName('head')[0].appendChild(node);
  //console.log("javaString within loadScript = " + this.javaString);

  }
public makeDivElement(){
  var div = this.renderer.createElement('div');
  //this.renderer.setProperty(this.abcd.nativeElement, 'id', 'sketchScript');
  //this.renderer.setProperty(this.abcd.nativeElement, 'src', 'assets/p5js-sketches/landingSketch1.js');
  //this.renderer.setProperty(this.abcd.nativeElement, 'type', 'text/javascript');
  //this.renderer.setProperty(this.abcd.nativeElement, 'async', 'false');
  //this.renderer.setProperty(this.abcd.nativeElement, 'charset', 'utf-8');

  //this.renderer.appendChild(this.abcd.nativeElement, div);
  console.log("div = ",div);
}

  }



