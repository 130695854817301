

<div class = "now">
  <div class = " now-content container-fluid">
    <h3>Now</h3>
    <hr>



    <div class = "row">

      <div class="newsBlock col-md-6 col-sm-12 col-lg-6 col-xs-12 ">
        <h4>  25th September, 2021.</h4>
        <h5>Dept of Works Exhibits at Paul Nache, Open 29th October - 29th November 2021</h5>
        <p>I am excited to be involved with the <em>Department of Works</em>, a collective created and led by Peter Adsett and Mary Alice Lee. Artists of DoW investigate ideas of the Formless (L'informe) as conceived by Georges Bataille (1929), revisited as a concept by Rosalind Krauss and Yves-Alain Bois in 1996. For Bataille, L'informe was about breaking down categories, questioning historical structures and challenging the high ideals of modernism. <br>The horizontal, material and process-driven outcomes of my practice relate directly to some of the ideas of formlessness. <br>Our first show is at <a href="https://www.paulnache.com/ " target="_blank">Paul Nache Gallery</a>, Gisborne, opening 29th of October through to 29th November.</p>
        <p><i>Architrave work, untitled, 27x27 cm. Splayed architrave, plywood, glue.</i></p>


      </div>

        <div class= "newsBlock col-md-6 col-sm-12 col-lg-6 col-xs-12 ">

              <img src = "assets/imgs/artImages/Architrave1.jpg" >

        </div>

    </div>


    <div class = "row">

      <div class="newsBlock col-md-6 col-sm-12 col-lg-6 col-xs-12 ">
            <h4>
                8th December, 2020.
            </h4>
            <h5>SIMULACRUM Film</h5>
            <p>A short film explaining my direction with SIMULACRUM has been uploaded, now active on YouTube <a href="https://www.youtube.com/watch?v=VPo5LXeAzKY&t=56s" target="_blank"> (press here)</a></p>

      </div>

        <div class= "newsBlock col-md-6 col-sm-12 col-lg-6 col-xs-12 ">

              <img src = "assets/imgs/artImages/Wayne_imageForFilm.jpg" >

        </div>

    </div>


      <div class = "row">


      <div class="newsBlock col-md-6 col-sm-12 col-lg-6 col-xs-12 ">
            <h4>
                8th October, 2020.
            </h4>
            <h5>SIMULACRUM</h5>

              <p>The word <i>Simulacrum</i>  can point to being a vague or superficial copy of an original image or thing. Andy Warhol's progressively degraded images of photographs of Marilyn Munroe can be considered simulacra, yet they also go somewhere else; by tapping into the culture of fame, they index something beyond mimetic representation. Warhol's screenprints become a testament to the blurry 'icons' of popular culture and in doing so ascend to the status of originals. Likewise, each work in SIMULACRUM is a unique,imperfect 'copy'. They index computer authorship of forms yet are hand-made. Many are also medium-bending; made with the methodology of printmaking, yet carefully constructed in the traditional materials of painting. A double simulacrum.  <br> SIMULACRUM opening is on Friday 6th November,5.30-7.30. The show runs until Saturday 19th December.</p>

            </div>
            <div class= "newsBlock col-md-6 col-sm-12 col-lg-6 col-xs-12 ">

              <img src = "assets/imgs/artImages/simulacrumPartial.jpg" >

            </div>


    </div>

    <div class = "row">


      <div class="newsBlock col-md-6 col-sm-12 col-lg-6 col-xs-12 ">
            <h4>
                7 October, 2020.
            </h4>
            <h5>Connecting with place</h5>



              <p>Road Works <a href="/print">(see in the gallery) </a> is about site and the recording of space and time, the act and the residue. This direction is borne out of a rolling frottage technique that I began to develop on with woodcuts in 2019, and it lends itself to more exploratory work outside the bounds of the studio.</p>





        </div>
            <div class= "newsBlock col-md-6 col-sm-12 col-lg-6 col-xs-12 ">

              <img src = "assets/imgs/artImages/Seaview1Partial.jpg" alt = "roadworksPartial">

            </div>


    </div>

    <div class = "row">


      <div class="newsBlock col-md-6 col-sm-12 col-lg-6 col-xs-12 ">
            <h4>
                20th August, 2020.
            </h4>
            <h5>Humbugaa pop-up event: Three Artists Talk About Their Parkin Prize Entries</h5>



              <p>Gill Newland, Billy Wilson and myself are giving a talk on Thursday 27th of August at 3.30pm. We're talking about our art practices as well as the Parkin entries that we have created. We are all part of an international collective known as Humbugaa, which is interested in the overlaps shared between art and architecture.</p>





            </div>
            <div class= "newsBlock col-md-6 col-sm-12 col-lg-6 col-xs-12 ">

              <img src = "assets/imgs/artImages/Parkin-100.jpg" >

            </div>


    </div>

    <div class = "row">


      <div class="newsBlock col-md-6 col-sm-12 col-lg-6 col-xs-12 ">
            <h4>
                6th July, 2020.
            </h4>
            <h5>Finalists for Parkin Drawing Prize Announced</h5>



              <p>It is a privilege to be short-listed for the Parkin this year alongside such strong and dedicated artists. The Parkin opens on the 4th of August through to the 30th at the Academy of Fine Arts, Wellington. </p>





            </div>
            <div class= "newsBlock col-md-6 col-sm-12 col-lg-6 col-xs-12 ">

              <img src = "assets/imgs/artImages/Revolver_WayneChurcher_partial.jpg" >

            </div>


    </div>





g g
    <div class = "row">


        <div class="newsBlock col-md-6 col-sm-12 col-lg-6 col-xs-12 ">
              <h4>
                  19th May, 2020.
              </h4>
              <h5>Exhibitions -postponed:</h5>


                <h4>PERMUTATION  Five Walls, Melbourne, Australia</h4>
                <p>PERMUTATION is dimensionally layered: physically, conceptually and through process. The use of code points to our make up as structural beings whose thought processes are built on the sign. Yet signs produced present only ambiguous gestalt operations of figure/ground, signification that is suspended within the viewer - the 'Chimeric Shifter'. </p>
                <p>Painting as medium is a valued convention, it's use having perceived worth as a commodity above other planar art forms. What if these signs are stolen? Would paintings' economy transfer to another medium? </p>




              </div>
              <div class= "newsBlock col-md-6 col-sm-12 col-lg-6 col-xs-12 ">

                <img src = "assets/imgs/artImages/Y23210_LBandR20387a.jpg" >

              </div>


      </div>



    <div class = "row">



        <div class="newsBlock col-md-6 col-sm-12 col-lg-6 col-xs-12 ">
              <h4>
                  11th May, 2020
              </h4>
              <h5>Continued Series </h5>


                    <h4>SIMULACRUM, (date to be announced for mid October) Potocki Paterson, Wellington NZ.
                    </h4>

                      <p>The authorship of composition has fallen to the sum of process and computer. Programs create visual keys, their interactions precipitating in original forms. What is the artist now? Engineer? Architect?<br>
                    Two formal ideas are also examined: the attack on the visual field by the disintegration of gestalt figure/ground expectations, and the analysis of printmaking as medium. <br></p>



              </div>
              <div class= "newsBlock col-md-6 col-sm-12 col-lg-6 col-xs-12 ">

                <img src = "assets/imgs/artImages/crit8.jpg" >

              </div>


      </div>

    <div class = "row">



        <div class="newsBlock col-md-6 col-sm-12 col-lg-6 col-xs-12 ">
              <h4>
                  22nd September, 2019.
              </h4>
              <h5>Flash Exhibition at Studio Pacific Architecture, Wellington</h5>
                <p>
                  This upcoming Thursday, 5.30pm at SPA, 74 Cuba St Wellington, I shall be taking you 'under the hood' of my art practice and revealing my mini-manifesto 'VIRBES'. The latest series PERMUTATION continues from REVOLUTION, looking at the interactions of differing 'key' elements. <a href="https://www.facebook.com/events/367351313926633/" target="_blank">VIRBES event page.</a>
                <p>
                <h4>Image: '1,2 2282 plus trans white 5355', layered screenprint(serigraph), 63x63cm.</h4>

              </div>

                <div class= "newsBlock col-md-6 col-sm-12 col-lg-6 col-xs-12 ">

                  <img src = "assets/imgs/artImages/12_2282plusTransWhite5355.jpg" >

                </div>

      </div>

                <div class = "row">


          <div class="newsBlock col-md-6 col-sm-12 col-lg-6 col-xs-12 ">
                <h4>
                    4th May, 2019.
                </h4>
                <h5>Merit Award for Printmaking</h5>
                  <p>
                    The finalists for the Cleveland National Art Awards showed strong and inspiring work and it was a privilege to be awarded the merit prize for printmaking with my work 'Human Error in the Code'.
                  </p>
                  <h4>Image: 'Human Error in the Code', layered screenprint(serigraph), 63x63cm.</h4>
                  <h4>"Thought provoking, fantastic use of monotone hues, a genetic masterpiece..." (Comments given with presentation)</h4>

          </div>


        <div class= "newsBlock col-md-6 col-sm-12 col-lg-6 col-xs-12 ">

          <img src = "assets/imgs/artImages/HumanErrorInTheCodePartial.jpg" >

        </div>
    </div>


    <div class = "row">


          <div class="newsBlock col-md-6 col-sm-12 col-lg-6 col-xs-12 ">
                <h4>
                    1st May, 2019.
                </h4>
                <h5>REVOLUTION Opens</h5>
                  <p>
                    After many months of shrouding my new work from cyberspace, they are revealed...53 works regarding my investigation into perception and process through modern lithography and screenprinting. REVOLUTION is at <a href="https://www.facebook.com/events/645121489250426/" target="_blank">Potocki Paterson Gallery, Dixon St Wellington</a> until the 12th of May.
                  </p>
                  <h4>Image: Entrance to Potocki Paterson Gallery, Wellington.</h4>

          </div>

        <div class= "newsBlock col-md-6 col-sm-12 col-lg-6 col-xs-12 ">

          <img src = "assets/imgs/artImages/DSC_0063.jpg" >

        </div>
    </div>


    <div class = "row">



          <div class="newsBlock col-md-6 col-sm-12 col-lg-6 col-xs-12" >
                <h4>
                    4th March, 2019.
                </h4>
                <h5>The Key Maker</h5>
                  <p>
                    In the Matrix Trilogy by the Wachowskis, the Key Maker is a character that allows the opening of 'back doors' within the Matrix world. Here in this world I have developed a program that creates and tests 'keys', the primary elements of manipulation inside my process. Each key made by the 'keyMaker' program is assessed, which introduces the pivot of choice in the whole affair. Though each key created is by a simple set of rules that do not have pictorial or aesthetic concerns, my choices are based on the 'empty sign, that is to say, each selected key  - and it's manipulation - attempts to stand clear of any physical referent.
                  </p>
                  <h4>Image: Shift0521v2, 50x35cm, 2019.</h4>

          </div>


        <div class= "newsBlock col-md-6 col-sm-12 col-lg-6 col-xs-12 ">

          <img src = "assets/imgs/artImages/Shift0521v2_lores.jpg" >

        </div>
    </div>


    <div class = "row">

          <div class="newsBlock col-md-6 col-sm-12 col-lg-6 col-xs-12 ">
                <h4>
                    1st March, 2019.
                </h4>
                <h5>The New Zealand Painting and Printmaking Awards 2019</h5>
                  <p>
                    I was honoured to be a finalist in this prestigious exhibition with the work Fav7f270, a multiple layered screen print. Opening night was on the 28th of February to a turnout of several hundered art lovers.
                  </p>
                  <h4>Image: Fav7f270, 70x70, 2018.</h4>

          </div>


        <div class= "newsBlock col-md-6 col-sm-12 col-lg-6 col-xs-12 ">

          <img src = "assets/imgs/artImages/Fav7f270_lores.jpg" >

        </div>
    </div>

    <div class = "row">



          <div class="newsBlock col-md-6 col-sm-12 col-lg-6 col-xs-12 ">
                <h4>
                  12th October 2018.
                </h4>
                <h5>Development of the REVOLUTION series</h5>
                  <p>
                    Literally each work in REVOLUTION involves a turn of the key - element that is. I am continually designing new keys driven by the novel interactions that occur in rotation. In the last few weeks screen printing of larger works have taken more of centre stage from my lithography. Work being made now is both towards exhibitions planned next year (Potoki Paterson Gallery, May 1st 2019 and another in Auckland, soon to be determined) and towards a submission for The New Zealand Painting and Printmaking Award coming up in November.
                  </p>
                  <h4>Image: Favicon 15, 1/2, 70x70cm, 2018.</h4>

          </div>

        <div class= "newsBlock col-md-6 col-sm-12 col-lg-6 col-xs-12 ">

          <img src = "assets/imgs/artImages/Favicon15_270deg.jpg" >

        </div>
    </div>


    <div class = "row">


          <div class="newsBlock col-md-6 col-sm-12 col-lg-6 col-xs-12 ">
                <h4>
                  1st July 2018.
                </h4>
                <h5>2018: The Birth of the Chimeric Shifter.</h5>
                  <p>
                    With the latest series REVOLUTION the intent is to slow the process down with even more simple elements. With some of the later works I am revealing what I have termed 'chimeric shifters', signs that can be activated as two things simultaneously. Shifters are signs - in this case the forms created on paper - that are empty of meaning until they are filled with significance by the viewer. The perceptual experience is that of figure and ground. Often in printmaking the technical support - paper - becomes the ground and the ink medium creates the figure. A 'chimeric shifter' is a shape that can both act as figure and ground simultaneously because of their mid-tone qualities and the presence of adjacent oppostional forms.
                    This new concept, while explored in REVOLUTION, shall be the subject of another subsequent series.
                  </p>

          </div>


        <div class= "newsBlock col-md-6 col-sm-12 col-lg-6 col-xs-12 ">

          <img src = "assets/imgs/artImages/DSC0639pt33x.jpg" >
        </div>
    </div>

    <div class = "row">


          <div class="newsBlock col-md-6 col-sm-12 col-lg-6 col-xs-12 ">
                <h4>
                  18th June, 2018.
                </h4>
                  <p>
                    A recently created print, Exp12J3composite1 (on left in photo), was accepted into the 'Works on Paper' exhibition organised by the Print Council Aoteoroa New Zealand (PCANZ) and curated by printmaker Mark Graver. The exhibition is held at ArtsPost Galleries, Hamilton, until 16 July, 2018.
                  </p>

          </div>

        <div class= "newsBlock col-md-6 col-sm-12 col-lg-6 col-xs-12 ">

          <img src = "assets/imgs/artImages/worksOnPaper2018_Exp12J3composite1.jpg" >
        </div>
    </div>


    <div class = "row">


          <div class="newsBlock col-md-6 col-sm-12 col-lg-6 col-xs-12 ">
                <h4>
                  5th June, 2018.
                </h4>
                  <p>
                    In 1967-68 The sculptor Richard Serra created a list of verbs  - the verblist - to help facilitate his practice.  This list comprises of what he called "actions to relate to oneself, material, place, and process".
                    <p>
                        The REVOLUTION has begun. Over the last two weeks I have been creating works that involve this title in several ways.
                    </p>


                  <p>
                    Referring to Serra, these works involve rotation of elements which command final forms through layering. The title REVOLUTION also refers to the critical juncture in the mid 1950's first recognised in the works by such artists as Pollock, Johns, Serra and Martin, where materiality and process began to take centre stage and in doing so shed all devices of illusion.
                  </p>
                  <p>
                    Consistent with this title there has been a quiet revolution within the path of my art practice, moving from predominantly figurative drawing through to printmaking methods and computer code.
                  </p>
                  <p>
                    REVOLUTION shall be exhibited around September, 2018.
                  </p>
          </div>


        <div class= "newsBlock col-md-6 col-sm-12 col-lg-6 col-xs-12 ">

          <img src = "assets/imgs/artImages/dryingRack2.jpg">
        </div>
    </div>

    <div class = " now-content container-fluid">

    <div class = "row">



          <div class="newsBlock col-md-6 col-sm-12 col-lg-6 col-xs-12 ">
                <h4>
                  28th April, 2018.
                </h4>
                  <p>
                    GRID has been open exactly one week and I am happy at the enthusiasm it has received from the public. The latest to sell is a small work that strongly represents my process and direction, Zeta 4 by 4a-1.
                  </p>
                  <p>
                    You can visit the GRID event page <a href="https://www.facebook.com/events/1722003104544136/" target="_blank">here.</a>
                  </p>
          </div>

        <div class= "newsBlock col-md-6 col-sm-12 col-lg-6 col-xs-12 ">

          <img src = "assets/imgs/artImages/Zeta4by4a-1_lores.jpg" >
        </div>
    </div>
    </div>

    <div class = " now-content container-fluid">

    <div class = "row">


        <div class="newsBlock col-md-6 col-sm-12 col-lg-6 col-xs-12 ">
                <h4>
                  5th March, 2018.
                </h4>
                  <p>
                    Last Friday I confirmed A new exhibition opening at ALTA Gallery, Newtown. Opening on Saturday 21st April,  at 5pm, SHIFT contains serigraphs and lithographs of manipulated forms created in code.
                  </p>
        </div>


          <div class= "newsBlock col-md-6 col-sm-12 col-lg-6 col-xs-12 ">

          <img src = "assets/imgs/artImages/Litho12a.jpg" >
          </div>
    </div>
    </div>

    <hr>

    <div class = " now-content container-fluid">

    <div class = "row">


          <div class="newsBlock col-md-6 col-sm-12 col-lg-6 col-xs-12 ">
                <h4>
                  18th February, 2018.
                </h4>
                  <p>
                    Continuing on from 2017 both of the GRID and SHIFT series of print works are still underway.
                    Both series are derived from shape fields from simple computer programs.
                    Colour and the interaction of screens is becoming a dominant feature in GRID. Limiting the number of screens has challenged me to play with varied layering sequences and colour/tonal interactions.
                  </p>
          </div>

          <div class= "newsBlock col-md-6 col-sm-12 col-lg-6 col-xs-12 ">

            <img src = "assets/imgs/artImages/Experiment12ib1.jpg" >
          </div>

          <div class= "newsBlock col-md-6 col-sm-12 col-lg-6 col-xs-12 ">
            <p>
              For many of the designs in GRID the separation of what the viewer sees as figure and what they see as ground is no longer clear. This ambivalence is present to keep the viewer in a kind of suspense, as they try to resolve the unresolvable. The term 'shifter' defines this perceptual phenomenon.
            </p>
          </div>

        <div class= "newsBlock col-md-6 col-sm-12 col-lg-6 col-xs-12 ">

          <img src = "assets/imgs/artImages/Litho11.jpg" >
        </div>
      </div>
    </div>
  </div>



