import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GroupService {

groupChosen:string = "Simulacrum";
groupTextChosen:string = "Show open at Potocki Paterson Gallery, November 7th through to December 19 2020, level 1, 41-47 Dixon St, Wellington. SIMULACRUM involves observing the medium as message. Algorithmically created key elements are layered, presenting as a unified field. These forms are copies of themselves and without an original. With 'SIMULACRUM' I am also interested in the artwork as object and what that means as a sign of value. For enquiries please contact Dylan on +640226943009, email dylan@potockipaterson.co.nz";

  constructor(private http: HttpClient) { this.getGroupData().subscribe(data => {
    console.log(data);
            }); }

  public getGroupData(): Observable<any>{
    return this.http.get('./assets/projectPack.json')
  }

}
