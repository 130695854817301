<div class = "bio-container container-fluid">
<h2>Biography</h2>
<p>
Born in Manukau, New Zealand, March, 1965.
</p>
<p>
Emigrated to Australia in 1989.
</p>
<p>
Current residence in Wellington, NZ 2015.
</p>
​
<h3>
Lectures/Discussions
</h3>
<p>2020. August 20, Academy of Fine Arts wellington. Three artists of the Humbugaa Art/Architecture Collective discuss their Parkin  Prize entries. </p>
<p>2020, March 8, Potocki Paterson Gallery, Wellington. Humbugaa Pop-up discussion on Simulacrum and its meaning today.</p>
<p>2020, March 9, Discussion on responding to architectural form and space through printmaking. Thistle Hall, Wellington, NZ.</p>
<p>
  2019, Discussion on REVOLUTION series and awareness of the sign, Festival of Art and Architecture, Havana Bar, Wellington NZ.
</p>
<h3>
Solo Exhibitions
</h3>
<p>2020, SIMULACRUM (now until Dec 19th 2020), Potocki Patterson Gallery, Wellington, NZ.</p>
<p>
  2019,
  REVOLUTION, Potocki Patterson Gallery, May 1st-12th.
</p>
<p>
2018, GRID, ALTA Gallery, Newtown, Wellington New Zealand.
</p>
<p>
  2017, CodaMorphology, Thistle Hall Gallery Cuba St., Wellington New Zealand.
</p>

<p>
  2011, Gallery Burlesque, St James Hotel, Sydney, NSW
</p>
<p>
  2005, Homage, The Global Art Gallery, Darlinghurst, NSW
</p>
<p>
  2000 Alabaster, Pastel Works, 555 Crown St Gallery, Surry Hills, NSW
</p>

<p>
  2000, Recent Works, 555 Crown St Gallery, Surry Hills, NSW
</p>
<p>
  1999, Torso, Regent St Gallery, Redfern, NSW
</p>


  <h3>
    Group Exhibitions
  </h3>
  <p>2021, October 29th - November 29th, Department of Works Collective at Paul Nache Gallery, Gisborne. </p>
  <p>2020, August 4-30th, Parkin Drawing Prize, New Zealand Academy of Fine Arts, Wellington.

  </p>
  <p>2020, March 9, 'To Be Continued', art and architecture group HumBugaa collective showing. Thistle Hall, Wellington, NZ.</p>
  <p>2019, The Cleveland National Art Awards, Dunedin, NZ</p>
  <p>
    2019, New Zealand Painting and Printmaking Awards (NZPPA), Hamilton, NZ.

  </p>
  <p>
    2018, 'Distant Notion', ArtConnect Gallery, Wyoming, USA.
  </p>
  <p>
    2018, 'Works on Paper', PCANZ Exhibition, ArtsPost Galleries, Hamilton.
  </p>
  <p>
    2017, MAC National Printmaking Exhibition, First Impressions III.
  </p>

<p>2016 100 Days, About Space, 120 High St, Lower Hutt, Wellington, NZ.
</p>
<p>2014, October, Painters Gallery Art Auction, Manly, Sydney, Australia.
</p>
<p>
  2014 Market Lane Street Art Project, Manly, Sydney.
</p>
<p>
  2014  M16 Artspace Drawing Prize, Canberra, ACT.
</p>
<p>
  2013  M16 Artspace Drawing Prize, Canberra, ACT.
</p>
<p>
  2012, Drawing 12A, Brunswick Street Gallery, Melbourne, Victoria.
</p>
<p>
  2011, Nowa Nowa Nudes, Art Home, Nowa Nowa, Victoria.
</p>
<p>
  2006  Northern Expressions Group Exhibition, Dale Thomas Gallery, Artarmon.
</p>
<p>
  2004, Good Beginnings Exhibition,  Guangzhou Province, China.
</p>
<p>
  2004, Warringah Printmakers Group Exhibition, Manly Vale, NSW.
</p>
<p>
  2002 Sacred Moments Art Festival, Kurrajong, NSW.
</p>
<p>
  2000, Warringah Printmakers Group Exhibition, Manly Vale, NSW.
</p>


<h3>Installations</h3>



<p>
  2014, October, 'Gamer', Charcoal, Aerosol Paint on Board, 2.5x2.5m, Market lane Art Project, Manly.
</p>
<p>
  2014,  June, 'Memory Box', Digital Generative Art Projected Installation, Painters Gallery, Manly.
</p>



<p>
  2014,  June, 'Together', Digital Generative Art Projected Installation, Painters Gallery, Manly.
</p>

<p>
  2014,  June, 'Awareness', Digital Generative Art Projected Installation, Painters Gallery, Manly.
</p>

<p>
  2014, July, 'Totem', Digital Generative Art Projected Installation, Painters Gallery, Manly.
</p>

<h3>
  Film
</h3>

<p>
  <a href="https://www.youtube.com/watch?v=VPo5LXeAzKY&t=63s" target="blank">Simulacrum, a discussion.</a>
</p>
<p>
  <a href="https://vimeo.com/107449900" target="_blank">Market lane Art Project, Manly.</a>
</p>

<h3>
  Awards

</h3>
<p>Finalist, Parkin Drawing Prize, Wellington 2020.</p>
<p>Merit Award for printmaking, the Cleveland National Art Awards 2019</p>
<p>
  Finalist  New Zealand Print and Printmaking Award (NZPPA), Hamilton, 2019
</p>
<p>
  Finalist PCANZ 'Works on Paper', curated exhibition, ArtsPost Galleries, Hamilton 2018
</p>
<p>
  Finalist MAC National Printmaking Exhibition, First Impressions III 2017
</p>
<p>
  Finalist in M16 Artspace Drawing Prize, Canberra, ACT, Australia 2014
</p>
<p>
  Finalist in M16 Artspace Drawing Prize, Canberra, ACT, Australia 2013
</p>
<p>
  2002 Sacred Moments Art Festival, 'Highly Commended Award' for Reclining Figure, Aluminium Plate etching.
</p>
<h3>
  Scholarships
</h3>
<p>
  Oriel Hoskin Scholarship, 2016
</p>

<h3>
  Commissions
</h3>

<p>
  2013, Private Commission, Tryptych, Manly Beach, 120x60cm giclee print 1/1.
</p>
<p>
  2012, Rave poster graphics
</p>
<p>
  2014, Promotional event artwork.
</p>


<h3>
  Art Studies
</h3>

<p>
  2018 (Advanced) Diploma of Art and Creativity, The Learning Connexion Wellington.
</p>

<p>
  2016 Diploma of Visual Art and Creativity(honours) The Learning Connexion Wellington.
</p>

<p>
  2010 Commenced BA(Visual Arts) Curtin University, Perth.
</p>

<p>
  2000, 2004 Printmaking Courses and works, Warringah.
</p>

<p>
  1986, 1987 Life studies, Outreach, Ponsonby Rd, Auckland.
</p>

<p>
  1986 Graphic Art Studies, Auckland TAFE.
</p>




<h3>
  Past Tertiary Studies
</h3>

<p>
  1983-1986 Bachelor of Science (Cell Biology), University of Auckland.
</p>

<p>
1992 Masters Preliminary Thesis (Molecular Genetics), University of Sydney.
  </p>
</div>
