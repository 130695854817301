import { Component, OnInit,Input, ViewChild, ElementRef} from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {GroupService} from '../group.service';
import { NavBarService } from '../nav-bar.service';


//import { HttpClient } from '@angular/common/http';
import { Subscription, timer } from 'rxjs';
import { GetImagesDataService } from '../get-imagesdata.service';
import { RetrieveService } from '../retrieve.service';
import { MongoItem } from '../MongoItem';
import { stringify } from 'querystring';
import {Group} from '../Group';

@Component({
  selector: 'app-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.css'],
  providers: [GetImagesDataService]
})
export class PrintComponent implements  OnInit {


  public groupArray:Group[]=[];
  groupString:string; //choosing the filter for group title from nav
  groupText:string = this.groupService.groupTextChosen; //accessing default inital text for collection descriptions
  filter = {group: this.groupService.groupChosen};//variable shifted to a singleton 'groupChosen in groupService
  public filterArray:[]=[];
  retrieveFile:string;
  technique:string;
  dimensions:string;
  date:string;
  title:string;// for modal, adding 4 strings.
  closeResult = '';
public siteItems: any = [];  // for retrieving items
dataLoaded: Promise<boolean>;


constructor(private retrieveService:RetrieveService,
  private modalService: NgbModal,
  element: ElementRef, private groupService:GroupService,public nav: NavBarService
  ) {}

  ngOnInit() {

    this.nav.show();
    //this.groupService.groupChosen = "Revolution";
    this.groupString = this.groupService.groupChosen;

    this.fetchData(); //fetching siteItems
    this.GroupData(); //for filtering main array

  }
  //for fetching data for images
  fetchData(){
    this.transfer();
  }

  transfer(){
    if (this.retrieveService.dataStoreLoaded==false){setTimeout(()=>{
      this.retrieveService.transferDataStore(this.retrieveService.dataStore).then(siteItems => this.siteItems = siteItems);
      this.retrieveService.dataStoreLoaded==true
    },3500);
  }
    else{
      this.retrieveService.transferDataStore(this.retrieveService.dataStore).then(siteItems => this.siteItems = siteItems);
    }
  }



//for modal


open(content,image,name,tech, dim,dte) {
  this.title = name;
  //console.log("title = ", this.title, "index: ",i);
  this.retrieveFile = image;
  this.technique = tech;
  this.dimensions = dim;
  this.date = dte;
  this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',size:'lg',windowClass:'myModal'}).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  });
}

private getDismissReason(reason: any): string {
  if (reason === ModalDismissReasons.ESC) {
    return 'by pressing ESC';
  } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    return 'by clicking on a backdrop';
  } else {
    return `with: ${reason}`;
  }
}

  GroupData(){
  //for fetching group info for nav + image selection
  this.groupService.getGroupData().subscribe(data => {

  this.groupArray = data.collection;
  console.log("groupData = ", this.groupArray)
  //console.log(this.sketchArray[1].name);
  });
  }

  groupPut(i:number){
    this.groupService.groupChosen = this.groupArray[i].group;//updating service held initial default.
    this.groupText = this.groupArray[i].text;
    this.filter = {group: this.groupService.groupChosen};
    this.groupString = this.groupService.groupChosen;
    //console.log("group chosen = ", this.groupService.groupChosen)
  }
}


