import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    Stitch,
    BSON,
    RemoteMongoClient,
    AnonymousCredential,
    RemoteMongoDatabase,
    StitchAppClient


} from 'mongodb-stitch-browser-sdk';
import { MongoItem } from './MongoItem';


@Injectable({providedIn:'root'})

export class GetImagesDataService {
  SiteItems:MongoItem[] = []; // for retrieving items
  constructor() {}

  db: RemoteMongoDatabase;
  client:StitchAppClient;

    initDB (){

    this.client = Stitch.initializeDefaultAppClient('waynariumdata-zfzpt');

    this.db = this.client.getServiceClient(RemoteMongoClient.factory, 'mongodb-atlas').db('artData');

    }

    public item:{
      _id:string;
      title:string;
      group:string;
      medium:string;
      technique:string;
      dimensions:string;
      date:string;
      support:string;
      file:string;
      key:string;
    }

    getMongoData(){
        return this.client.auth.loginWithCredential(new AnonymousCredential()).then(()=>{ return this.db.collection<any>('siteItems').find().asArray();
      });
      }

      async onFetchData(){
        let firstResult = await this.getMongoData().then(SiteItems => this.SiteItems = SiteItems);
        console.log("Server siteItems from MongoDB: ", this.SiteItems);
        return this.SiteItems;

      }


}
