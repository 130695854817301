import { Component, OnInit } from '@angular/core';
import { NavBarService } from '../nav-bar.service';


@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {


  constructor(public nav: NavBarService ) { }

  ngOnInit() {
this.nav.hide();

}


}
