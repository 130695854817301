import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import {GetSketchesService} from '../get-sketches.service';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {faChevronRight} from '@fortawesome/free-solid-svg-icons';



@Component({
  selector: 'app-cyberspace',
  templateUrl: './cyberspace.component.html',
  styleUrls: ['./cyberspace.component.css']
})

export class CyberspaceComponent implements OnInit {

public showSelected : boolean;//show/hide property before buttons
public showReturnButton :boolean; // show hide return button to cyberMainPage
public sketchArray;
public sketchChosen : boolean; //to trigger adjusting sketchHolder and corresponding script.
faChevronRight = faChevronRight;

public clickSketchButton = function(){

  this.showSelected = false;
  this.showReturnButton = true;
  //console.log("showSelected clicked");
}
public clickReturnButton = function(){

  this.showReturnButton = false;
  this.showSelected = true;
  this.hideNavBar;

  //console.log("showReturnButton clicked ");

}


  constructor(private getSketchesService:GetSketchesService) { }

    ngOnInit() {
       this.showSelected = true; //for showing container of buttons
       this.showReturnButton = false;

    this.sketchArray = [];
    this.getSketchesService.getSketchData().subscribe(data => {

      //  console.log(data.sketches[2].name);
    this.sketchArray = data.sketches;
        //console.log(this.sketchArray[1].name);
    });


  }

}
