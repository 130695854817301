import { Component, Renderer2, OnInit, Inject } from '@angular/core';
import { NavBarService } from '../nav-bar.service';

@Component({
  selector: 'app-real-world',
  templateUrl: './real-world.component.html',
  styleUrls: ['./real-world.component.css']
})


export class RealWorldComponent implements OnInit {
  constructor(public nav: NavBarService) {}



  public ngOnInit() {
    this.nav.show()
  }


}
