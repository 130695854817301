

  <div class = "myFooter container-fluid bd-dark">

    <div class = "row">
      <div class="social-networks col-lg-4 col-md-4 col-sm-12">
        <a href="https://www.facebook.com/wonderdog999/" target="_blank" class="facebook icon" ><i class="fab fa-facebook-square"></i></a>
        <a href="https://www.instagram.com/waynechurcher/?hl=en" target="_blank" class="instagram icon"><i class="fab fa-instagram"></i></a>

    </div>

    <div class="otherStuff col-lg-12 col-md-12 col-sm-12" >
        <p>© 2020 Copyright Wayne Churcher </p>
    </div>



    </div>


  </div>
