import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class RetrieveService{

public Items:{};
public dataStore: any = {};// store our data in memory
private $subject = new Subject<any>();
public $data = new Subject<any>();
public firstLoad = true;
public dataStoreLoaded: boolean = false;



  constructor() {}

//observable from app
  sendData(items:{}) {
    this.$subject.next({items});
    this.dataStore = items;
    console.log("datastore = : ", this.dataStore);
     //called by appComponent
}

    //called by printComponent
  getData(){
    if (this.dataStore.length > 0){
      this.dataStoreLoaded = true;
      return this.dataStore;
    }
  }
  //observable to print
 async transferDataStore(dataStore){
 this.$data.next({dataStore});
 return dataStore;
 }


}
