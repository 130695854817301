

<div class = "cyberpage">

    <div *ngIf = "showReturnButton">
        <div  class = "container">
            <div id = "buttonReturn">
              <button id = "close" type="button"  routerLink="/cyberspace" routerLinkActive="active" (click) = clickReturnButton()><p> x </p>
              </button>
            </div>
          </div>
      </div>


    <div *ngIf="showSelected">

        <div  id = "contained-list" class = "container">
              <div id = 'sketchList' class = "row" *ngIf = "sketchArray">
                  <div class = "listContainer  col-lg-4 col-md-6 col-sm-6 col-xs-12" *ngFor="let sketch of sketchArray" >
                      <div id = "sketchBlock"  routerLink="sketches/{{sketch.name}}" routerLinkActive="active" (click) = clickSketchButton() ngbPopover= {{sketch.info}} triggers="mouseenter:mouseleave" >
                        <div class=" inline btn-group " role="group" aria-label="Basic example" >
                          <button type="button" class="btn btn-secondary first-btn" placement="bottom" >{{sketch.name}}
                          </button>
                        </div>
                          <div  id = "imgCont" class = "  d-block col-lg-12 col-md-12 col-sm-12">
                            <img id = "thumbnail"  src="assets/imgs/sketchThumbnails/{{sketch.thumbnail}}.jpeg" alt="{{sketch.thumbnail}}">
                          </div>


                      </div>

                  </div>
        </div>



      <div>
                    <h4>
                      Cyber Sketches
                    </h4>
                    <p class = "text col-md-8 col-lg-6 col-sm-12">
                    These simple program outputs are not narratives, nor are they mimetic forms. They are generative forms born of investigations into the interactions of primitive shapes, with the intent of encouraging the viewer to question what they are viewing.
                    </p>
                    <p class = "text col-md-8 col-lg-6 col-sm-12">                      Some of the works take a little time to initiate, so please be patient to achieve the full experience.  A little time is also required to meditate on how the sketches operate. To close, click the top right 'X'.
                    </p>
                </div>
  </div>


</div>



    <router-outlet></router-outlet>
</div>
