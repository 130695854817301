import { HttpClientModule } from '@angular/common/http';
//import { Observable } from 'rxjs';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms'; // <-- NgModel lives here
import { AppComponent } from './app.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { HomePageComponent } from './home-page/home-page.component';
import { RouterModule, Routes } from '@angular/router';
import { FeedComponent } from './feed/feed.component';
import { NowComponent } from './now/now.component';
import { PrintComponent } from './print/print.component';
import { NonPrintComponent } from './non-print/non-print.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { CyberspaceComponent } from './cyberspace/cyberspace.component';
import { RealWorldComponent } from './real-world/real-world.component';
import { SketchesComponent } from './sketches/sketches.component';
import {GetSketchesService} from './get-sketches.service';
import {GetImagesDataService} from './get-imagesdata.service'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { AppFooterComponent } from './app-footer/app-footer.component';
import { BioComponent } from './bio/bio.component';
import { FilterArgsPipe } from './filter-args.pipe';
import { FrontSketchComponent } from './front-sketch/front-sketch.component';
import { NavBarService } from './nav-bar.service';



const appRoutes: Routes = [
  { path: 'home-page', component: HomePageComponent,
children:[
  {path:'frontSketch', component:FrontSketchComponent}
] },
  { path: 'feed', component: FeedComponent},
  { path: 'bio', component: BioComponent},
  { path: 'cyberspace', component: CyberspaceComponent,
  children:[
    {path: 'sketches/:id', component:SketchesComponent}
  ]},
  {path: 'print', component:PrintComponent},

  {path: 'real-world', component:RealWorldComponent},

  { path: 'now', component: NowComponent},

  { path: '',
    redirectTo: 'home-page/frontSketch',
    pathMatch: 'full'
  },
  { path: '**', component: PageNotFoundComponent }
];



@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    HomePageComponent,
    FeedComponent,
    NowComponent,
    PrintComponent,
    NonPrintComponent,
    CyberspaceComponent,
    RealWorldComponent,
    SketchesComponent,
    PageNotFoundComponent,
    AppFooterComponent,
    BioComponent,
    FilterArgsPipe,
    FrontSketchComponent,
  ],
  imports: [
    NgbModule,
    RouterModule.forRoot(appRoutes, { useHash: false }),
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: true } // <-- debugging purposes only
    ),

    BrowserModule,
    FormsModule,
    HttpClientModule,
    FontAwesomeModule,
  ],

  providers: [
    GetSketchesService,
    GetImagesDataService,
  NavBarService],
  bootstrap: [AppComponent]
})
export class AppModule { }
