<router-outlet></router-outlet>

<header class="jumbotron jumbotron-fluid">
  <div class="container-fluid text-center">
     <h3 class="display-3 jumbo">Wayne Churcher</h3>
     <p class="lead pb-4 jumbo"></p>

  </div>
</header>



<section id="services" class="container">


  <div class="row text-center">

     <div class="col-md-4 mb-4">
        <div class="card h-100">
          <p><a class="nav-link"  routerLink="/now" routerLinkActive="active"  > <img class="card-img-top" src="assets/imgs/studioDeskwithPlates.jpg" ></a>
          </p>

           <div class="card-body">
              <h4 class="card-title">Now</h4>
              <p class="card-text">Blog announcing and discussing current projects</p>
           </div>

        </div>

     </div>
<!--
     <div class="col-md-3 mb-4">
        <div class="card h-100">
          <p><a class="nav-link"  routerLink="/print" routerLinkActive="active"  > <img class="card-img-top" src="assets/imgs/artImages/8pm8_112017.jpg" ></a>
          </p>


           <div class="card-body">
              <h4 class="card-title">Gallery</h4>
                 <p class="card-text">Archive of Projects and Series </p>
           </div>

        </div>
     </div>
-->
     <div class="col-md-4 mb-4">
        <div class="card h-100">
          <p><a class="nav-link"  routerLink="/bio" routerLinkActive="active"  ><img class="card-img-top" src="assets/imgs/frontPage/DSC_0063.jpeg"></a>
          </p>
           <div class="card-body">
              <h4 class="card-title">Bio</h4>
              <p class="card-text">Timeline of Exhibitions, Talks and Projects</p>
           </div>

        </div>
     </div>
          <div class="col-md-4 mb-4">
        <div class="card h-100">
          <p><a class="nav-link"  routerLink="/real-world" routerLinkActive="active"  ><img class="card-img-top h-80" src="assets/imgs/artImages/0544_1of5.jpg"></a>
          </p>
           <div class="card-body ">
              <h4 class="card-title ">Archive</h4>
              <p class="card-text">Collections</p>
           </div>

        </div>
     </div>


  </div>
</section>

